<template>
	<!-- 教师考勤-->
	<div class="teacherAttendance">
		<div class="section">
			<!-- 我的出勤组件 -->
			<myAttence ref='myAttence' style="padding: 0 12px;"></myAttence>	
		</div>
	</div>
</template>

<script>
	import {
		Image as VanImage,
		Tab,
		Tabs,
		Calendar
	} from 'vant'

	import myAttence from '../teacherAttendance/myAttence/index.vue'

	export default {
		name: 'teacherAttendance',
		components: {
			myAttence,
			[VanImage.name]: VanImage,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Calendar.name]: Calendar
		},
		data() {
			return {
				tabNum: 0,
			}
		},
		mounted() {

		},
		methods: {
		}
	}
</script>

<style lang="less">
	.teacherAttendance {
		//padding: 10px 12px;
		height: 100%;
		display: flex;
		flex-direction: column;
		.topBar {
			font-size: 14px;
			width: 72%;
			margin: 10px auto 0px;
		}
		.section{
			flex: 1;
			margin-top: 10px;
		}
	}
</style>
