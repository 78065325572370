<template>
	<div class="myAttence">
		<div class="calendar">
			<div class="date" @click="openMouth">
				<span>{{year}}</span>
				<span class="line"></span>
				<span >{{mouthDay}}</span>
			</div>
			<!-- 月数据 -->
			<div class="mouthDay" style="margin-top: 10px;">
				<div class="box" v-for="(item,index) in mouthList" :key="item"
					:class="[currentIndex == index  ? 'active':'']" @click="tabsclick(index)">
					{{item + '月'}}
				</div>
			</div>
			<!-- 出勤天数 -->
			<div class="attendceDay">
				<div>
					<p>{{AttendanceDay}}</p>
					<p>出勤天数</p>
				</div>
				<div class="">
					<p>{{LeaveDay}}</p>
					<p>请假天数</p>
				</div>
				<div class="">
					<p>{{RestDay}}</p>
					<p>休息天数</p>
				</div>
			</div>
		</div>
		<selcetMouth ref="selcetMouth" @timeChange='timeChange'></selcetMouth>
	</div>
</template>

<script>
	import {
		Icon
	} from 'vant';

	import selcetMouth from '@/components/common/selectMouth/index.vue'


	export default {
		data() {
			return {
				mouthList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
				currentIndex: Number(new Date().getMonth()),
				mouthDay: '', //选中的月份
				AttendanceDay: 0, //出勤天数
				RestDay: 0, //休息天数
				LeaveDay: 0, //请假天数
				year: '',
			}
		},
		components: {
			[Icon.name]: Icon,
			selcetMouth
		},
		mounted() {
			this._mouthDate()
			this._getMouthAttence()
		},
		methods: {
			// 选中某月
			tabsclick(index) {
				this.currentIndex = index
				this.mouthDay = index + 1 + '月'
				// 初始为出勤天数
				this.$parent.tabNum = 0
				this.$parent.$refs.topBar.tabNum = 0
				this._getMouthAttence()
			},
			// 底部点击选择
			tabClick(index) {
				this.tabNum = index
				this.dayList = []
				this.dayList = this.tabNum == 0 ? this.AttendanceDayDtos : this.tabNum == 1 ? this.LeaveDayDtos : this
					.RestDayDtos
				console.log(this.dayList)
				if (this.dayList.length > 0) {
					this.dayList.map(item => {
						item.Date = this.$moment(new Date(item.Date)).format("YYYY-MM-DD")
					})
				}
			},
			// 月数据(默认当前月)
			_mouthDate() {
				this.year = new Date().getFullYear()
				this.mouthDay = new Date().getMonth() + 1 + '月'
			},
			// 获取月下列表数据
			_getMouthAttence() {
				let model = {
					dayMonth: 2, //月
					year: this.year,
					month: this.currentIndex + 1,
				}
				this.$axios.get('/api/AttendanceStatistics/myAttendance',
					model
				).then(res => {
					//console.log(res)
					if (res.code == 200) {
						this.AttendanceDay = res.data.AttendanceDay
						this.LeaveDay = res.data.LeaveDay
						this.RestDay = res.data.RestDay
						// 父组件下拉列表数据赋值
						let $parent = this.$parent
						$parent.RestDayDtos = res.data.RestDayDtos
						$parent.dayList = $parent.AttendanceDayDtos = res.data.AttendanceDayDtos
						$parent.LeaveDayDtos = res.data.LeaveDayDtos
						console.log($parent.dayList)
						// 默认展示出勤天数数据
						if ($parent.dayList.length > 0) {
							$parent.dayList.map(item => {
								item.Date = this.$moment(new Date(item.Date)).format("YYYY-MM-DD")
							})
						}
						//this.RestDay = attendanceDto.RestDay
						//this.LeaveDay = attendanceDto.LeaveDay
					}
				})
			},
			// 选择年月
			openMouth() {
				this.$refs.selcetMouth.show = true
			},
			// 改变年月
			timeChange(time){
				this.year = time.slice(0,4)
				this.mouthDay = Number(time.slice(5)) + '月'
				this.currentIndex = Number(time.slice(5) -1)
				// 初始为出勤天数
				this.$parent.tabNum = 0
				this.$parent.$refs.topBar.tabNum = 0
				// 刷新数据
				this._getMouthAttence()
			}
		}
	}
</script>

<style lang="less" scoped>
	.calendar {
		margin-top: 20px;
		width: 100%;

		.attendceDay {
			display: flex;
			width: 68%;
			justify-content: space-between;
			padding-left: 10px;
			margin-top: 30px;

			div {
				text-align: center;
				justify-content: space-between;

				p:first-child {
					font-size: 32px;
				}

				p:last-child {
					margin-top: 4px;
				}
			}
		}

		.mouthDay {
			display: flex;
			flex-wrap: wrap;

			.box {
				min-width: 25px;
				height: 25px;
				padding: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
			}

			.active {
				background-color: #47AFA7;
			}
		}

		.date {
			height: 15px;
			position: relative;
			line-height: 15px;
			display: flex;
			align-items: center;

			.line {
				display: inline-block;
				width: 1.3px;
				height: 12px;
				background-color: #ebedf0;
				margin: 0 8px;
				position: relative;
				bottom: 1px;
				left: 1px;
			}

			span {
				font-size: 16px;
			}
		}
	}
</style>
