<template>
	<div class="myAttence">
		<div class="my-card" :class="value?'active':''">
			<!-- 顶部用户数据 -->
			<div class="top">
				<div class="left">
					<van-image width="40" height="40" radius='30%' :src="ProfilePicture" class="img" />
					<span style="margin-left: 10px;">{{RealName}}</span>
				</div>
				<!-- 日历切换 -->
				<div class="right">
					<van-tabs type="card" v-model='value'>
						<van-tab title="日"></van-tab>
						<van-tab title="月"></van-tab>
					</van-tabs>
				</div>
			</div>
			<!-- 日历数据 -->
			<div class="calendarInfo">
				<div class="calendar">
					<div class="date" v-show="!value">
						<span>{{year}}</span>
						<span class="line"></span>
						<span>{{mouthDay}}</span>
					</div>
					<!-- 日数据组件-->
					<dayInfo ref="dayInfo" v-if="!value"></dayInfo>
				</div>
				<!-- 月历数据 -->
				<mouthInfo ref="mouthInfo" v-show="value"> </mouthInfo>
			</div>
		</div>

		<!-- 底部出勤天数 -->
		<div class="" v-show='value'>
			<!-- 底部天数类型选择 -->
			<div class="bottom my-card" style="margin-top: 40px;height: 360px;">
				<topBar ref='topBar' :tabs="['出勤天数','请假天数','休息天数']" style="font-size: 14px;" @tabNum='tabClick'
					class="topBar">
				</topBar>
				<!-- 日期选择 -->
				<div class="selectType">
					<div v-for="item in dayList" :key="item.Date">
						<div class="box" v-if="dayList.length > 0">
							<div class="left">
								{{item.Date}}
							</div>
							<div class="right" @click='dayDetails(item)'>
								<van-icon name="arrow" />
							</div>
						</div>
					</div>
					<div class="" v-show='dayList.length == 0'
						style="text-align: center;margin-top: 20px;font-size: 16px;">
						暂无数据
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Image as VanImage,
		Tab,
		Tabs,
		Calendar,
		Icon
	} from 'vant'

	import topBar from '@/components/common/topBar/index.vue'
	import dayInfo from './dayInfo/index.vue'
	import mouthInfo from './mouthInfo/index.vue'

	export default {
		name: 'MyAttence',
		components: {
			dayInfo,
			mouthInfo,
			topBar,
			[VanImage.name]: VanImage,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Calendar.name]: Calendar,
			[Icon.name]: Icon
		},
		data() {
			return {
				value: 0,
				ProfilePicture: JSON.parse(window.localStorage.getItem('userInfo')).HeadUrl,
				RealName: JSON.parse(window.localStorage.getItem('userInfo')).RealName,
				year: '', //年
				mouthDay: '', //月和日
				currentDay: '', //格式化当前天
				dayList: [], //底部数据
				tabNum: 0,
				AttendanceDayDtos: [], //出勤天数数据
				RestDayDtos: [], //休息天数数据
				LeaveDayDtos: [], //请假天数数据
			}
		},
		methods: {
			// 底部点击选择
			tabClick(index) {
				this.tabNum = index
				this.dayList = []
				this.dayList = this.tabNum == 0 ? this.AttendanceDayDtos : this.tabNum == 1 ? this.LeaveDayDtos : this
					.RestDayDtos
				if (this.dayList.length > 0) {
					this.dayList.map(item => {
						item.Date = this.$moment(new Date(item.Date)).format("YYYY-MM-DD")
					})
				}
			},
			// 具体选中某一天
			dayDetails(item) {
				// 日历组件数据改变
				this.value = 0 //切花回日数据
				this.$nextTick(()=>{
					this.$refs.dayInfo.$refs.calendar.currentDate = new Date(item.Date)
					this.currentDay = item.Date
					this.mouthDay = this.currentDay.slice(5)
					// 刷新底部日历出勤状态
					this.$refs.dayInfo._getMoutnState()
				})			
			},
		}
	}
</script>

<style lang="less" scoped>
	.myAttence {
		height: 100%;

		.my-card {
			height: 680px;
			display: flex;
			flex-direction: column;
			
			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #ebedf0;
				//height: 10%;
				padding-bottom: 10px;
				.left {
					display: flex;
					align-items: center;
				}
			}

			.calendarInfo {
				flex: 1;
				//overflow: hidden;
				.calendar {
					margin-top: 20px;
					width: 100%;

					.attendceDay {
						display: flex;
						width: 68%;
						justify-content: space-between;
						padding-left: 10px;
						margin-top: 4px;

						div {
							text-align: center;
							justify-content: space-between;

							p:first-child {
								font-size: 32px;
							}

							p:last-child {
								margin-top: 4px;
							}
						}
					}

					.mouthDay {
						display: flex;
						flex-wrap: wrap;

						.box {
							min-width: 25px;
							height: 25px;
							padding: 10px;
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 50%;
						}

						.active {
							background-color: #00aaff;
						}
					}

					.date {
						height: 15px;
						position: relative;
						line-height: 15px;
						display: flex;
						align-items: center;

						.line {
							display: inline-block;
							width: 1.3px;
							height: 12px;
							background-color: #ebedf0;
							margin: 0 8px;
							position: relative;
							bottom: 1px;
							left: 1px;
						}

						span {
							font-size: 16px;
						}
					}
				}
			}

		}

		.bottom {
			height: 100%;
			display: flex;
			flex-direction: column;

			.topBar {
				height: 2%;
				display: flex;
				margin: 0px;
			}

			.selectType {
				flex: 1;
				overflow: scroll;

				.box {
					height: 30px;
					border-bottom: 1px solid #ebedf0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					color: #a6a6aa;
					font-size: 16px;
					padding: 10px 0px;
				}
			}

		}

		.active {
			height: 300px!important;
		}
	}
</style>
