<template>
	<div class="myAttendance">
		<!-- 日数据 -->
		<div class="dayInfo" style="margin-top: 10px">
			<van-calendar title="日历" :poppable="false" :show-confirm="false"  style="height: 50vh"
				:show-title='false' @select='selectDay' ref="calendar" :min-date='minDate' :max-date='maxDate' />
		</div>
		<!-- 出勤统计数据 -->
		<div class="info" style="margin-top: 10px;padding: 0 10px;display: flex;flex-direction: column;height: 30%;">
			<div style="height: 10%;margin-top: 20px;">出勤统计:
				<span>{{childerState == 9 ? '请假': childerState == 1 ? '已签到':'今日休息'}}</span>
			</div>
			<div style="margin-top: 10px;height: 140px;overflow: scroll;">
				<!-- 步骤条 -->
				<van-steps direction="vertical">
					<van-step v-for='item in timeList' :key='item.ID'>{{item.AttendanceDate}}</van-step>
				</van-steps>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Calendar,
		Step,
		Steps
	} from 'vant'

	export default {
		data() {
			return {
				year: '',
				childerState: '',
				minDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), 1),
				maxDate: new Date(),
				timeList: [],
			}
		},
		components: {
			[Calendar.name]: Calendar,
			[Step.name]: Step,
			[Steps.name]: Steps
		},
		mounted() {
			this.currentDate()
			this._getMoutnState()
		},
		methods: {
			//选中某天
			selectDay(data) {
				let selecDay = this.$moment(new Date(data)).format("YYYY-MM-DD")
				this.$parent.year = Number(selecDay.slice(0, 4))
				this.$parent.mouthDay = selecDay.slice(5)
				this.$parent.currentDay = selecDay
				this._getMoutnState()
			},
			// 当天数据
			currentDate() {
				let currentDate = this.$refs.calendar.currentDate
				let data = this.$moment(new Date(currentDate)).format("YYYY-MM-DD")
				this.$parent.year = Number(data.slice(0, 4))
				this.$parent.mouthDay = data.slice(5)
				this.$parent.currentDay = data
			},
			// 查看当前宝贝日考勤状态
			_getMoutnState() {
				let model = {
					date: this.$parent.currentDay,
					dayMonth: 1 //日
				}
				this.$axios.get('/api/AttendanceStatistics/myAttendance',
					model
				).then(res => {
					if (res.code == 200) {
						this.childerState = res.data && res.data.length > 0 ? res.data[0].AttendanceType : ''
						this.timeList = res.data
						if (this.timeList.length > 0) {
							this.timeList.map(item => {
								// item.AttendanceDate = this.$moment(new Date(item.AttendanceDate)).format(
								// 	"YYYY-MM-DD hh:mm");
									item.AttendanceDate = item.AttendanceDateStr;
									console.log(item.AttendanceDate)
							})
						}
						
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.myAttendance {
		height: 100%;
		/deep/ .van-step__icon {
			color: #969799 !important;
		}

		/deep/ .van-step__title--active {
			color: #969799 !important;
		}

		/deep/ .van-icon-checked:before {
			content: '';
			display: block;
			width: 5px;
			height: 5px;
			background-color: #969799;
			border-radius: 50%;
		}
	}
</style>
